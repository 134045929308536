import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CoreModule } from '@page2flip/core';
import { CoreCommonModule } from '@page2flip/core/common';
import { CoreModule as CreatorCoreModule } from '../core/core.module';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PDFRequirementsDialogComponent } from './pdf-requirements-dialog/pdf-requirements-dialog.component';
import { PublicationDeleteDialogComponent } from './publication-delete-dialog/publication-delete-dialog.component';
import { PublicationListComponent } from './publication-list/publication-list.component';
import { SettingsComponent } from './settings/settings.component';
import { UploaderComponent } from './uploader/uploader.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    CreatorCoreModule,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    CommonModule,
    CoreCommonModule,
    MaterialModule
  ],
  declarations: [
    FeedbackComponent,
    HomeComponent,
    InfoComponent,
    LoginComponent,
    NotFoundComponent,
    PDFRequirementsDialogComponent,
    PublicationDeleteDialogComponent,
    PublicationListComponent,
    SettingsComponent,
    UploaderComponent
  ],
  entryComponents: [
    PDFRequirementsDialogComponent,
    PublicationDeleteDialogComponent
  ],
  providers: [DatePipe]
})
export class BaseModule {}
