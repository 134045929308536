import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { CoreCommonModule } from '@page2flip/core/common';
import { MaterialModule } from '../material/material.module';

@NgModule({
  exports: [
    ColorPickerModule,
    CommonModule,
    CoreCommonModule,
    FormsModule,
    MaterialModule,
    PerfectScrollbarModule,
    VirtualScrollModule
  ]
})
export class SharedModule {}
