import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NxModule } from '@nrwl/nx';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import { HammerConfig, PerfectScrollbarConfig, WINDOW, windowFactory } from '@page2flip/core';
import { PaginatorIntl } from '@page2flip/i18n';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { bugsnagFactory } from './bugsnag.factory';
import { BaseModule } from './base/base.module';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';

registerLocaleData(localeDe, 'de-DE'); // FIXME

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BaseModule,
    NxModule.forRoot(),
    RouterModule.forRoot(routes)
  ],
  providers: [
    { provide: ErrorHandler, useFactory: bugsnagFactory },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: PerfectScrollbarConfig },
    { provide: WINDOW, useFactory: windowFactory }
  ]
})
export class AppModule {}
