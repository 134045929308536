import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

/**
 * Component that represents the PDF requirements dialog.
 */

@Component({
  selector: 'p2f-pdf-requirements-dialog',
  templateUrl: './pdf-requirements-dialog.component.html',
  styleUrls: ['./pdf-requirements-dialog.component.css']
})
export class PDFRequirementsDialogComponent {

  /**
   * Constructor of the component.
   *
   * @param dialogRef Reference to the dialog instance.
   */
  constructor(public dialogRef: MatDialogRef<PDFRequirementsDialogComponent>) {}

}
