import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { environment } from '../../../../../apps/creator/src/environments/environment';

/**
 * Service for uploading files to the backend.
 */
@Injectable()
export class UploaderService {

  /** Subject that controls the data passed to the uploader. */
  private readonly _data: Subject<File> = new Subject<File>();

  /** Subject that controls the errors passed to the uploader. */
  private readonly _error: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  /** Subject that resets the file input. */
  private readonly _reset: Subject<void> = new Subject<void>();

  /**
   * Constructor of the service.
   *
   * @param http  Service to perform HTTP requests.
   */
  constructor(private http: HttpClient) {}

  /**
   * Gets an observable that is notified when a file has been send to the uploader.
   */
  data(): Observable<File> {
    return this._data.asObservable();
  }

  /**
   * Gets an observable that is notified when an upload error happened.
   */
  error(): Observable<HttpErrorResponse> {
    return this._error.asObservable();
  }

  /**
   * Gets an observable that is notified when the file input gets reset.
   */
  reset(): Observable<void> {
    return this._reset.asObservable();
  }

  /**
   * Sends a file to the uploader.
   *
   * @param file  The file.
   */
  sendFile(file: File) {
    this._data.next(file);
  }

  /**
   * Resets the file input.
   */
  resetFileInput() {
    this._reset.next();
  }

  /**
   * Uploads a PDF file.
   *
   * @param file  The PDF file.
   */
  uploadFile(file: File): Observable<HttpResponse<any>> {
    const itemJson: string = JSON.stringify({
      publication: { de: { location: 'mpr:pdf' } },
      sortKey: 0,
      type: 'DOCUMENT'
    });
    const blob: Blob = new Blob([itemJson], { type: 'application/json' });

    const formData: FormData = new FormData();
    formData.append('item', blob);
    formData.append('pdf', file);

    return this.http
      .post(`${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/items/gridItem`, formData, { observe: 'response', reportProgress: true })
      .pipe(catchError((error: HttpErrorResponse) => {
        this._error.next(error);
        return throwError(error);
      }));
  }

}
