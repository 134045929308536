import BugsnagErrorHandler from 'bugsnag-angular';
import bugsnag from 'bugsnag-js';

import { application } from '@page2flip/core';
import { environment } from '../environments/environment';

const bugsnagClient = bugsnag({
  apiKey: '765e58b48d229b39f1e04c35292a551f',
  appVersion: application.version + ' (#' + application.revision + ')',
  releaseStage: environment.production ? 'production' : 'development',
  notifyReleaseStages: ['production']
});

export function bugsnagFactory() {
  return new BugsnagErrorHandler(bugsnagClient);
}
