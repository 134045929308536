export { languages } from './lib/languages';
export { locales } from './lib/locales';
export { PaginatorIntl } from './lib/paginator-intl.service';
export { translations as cs } from './lib/translations/cs.translation';
export { translations as de } from './lib/translations/de.translation';
export { translations as es } from './lib/translations/es.translation';
export { translations as fr } from './lib/translations/fr.translation';
export { translations as it } from './lib/translations/it.translation';
export { translations as sv } from './lib/translations/sv.translation';
export { translations as zh } from './lib/translations/zh.translation';
