import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  /**
   * Constructor of the guard.
   *
   * @param auth    Service to authenticate the user.
   * @param router  Provides the navigation and url manipulation capabilities.
   */
  constructor(private auth: AuthenticationService,
              private router: Router) {}

  /**
   * Decides if a route can be activated.
   *
   * @param route   Contains the information about a route associated with a component loaded in an outlet.
   * @param state   Represents the state of the router at a moment in time.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.auth.profile().subscribe(user => {
        if (user && user.apiAuthKey) {
          this.auth.currentUser = user;
          observer.next(true);
          observer.complete();
        }
      }, (error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // TODO: inform user about client-side error
        } else {
          // TODO: inform user about backend-side error
          if (error.message) {
            if (error.status === 401) {
              this.auth.redirectUrl = state.url;
              this.router.navigate(['/login']);
              observer.next(false);
              observer.complete();
            }
          }
        }
      });
    });

  }

}
