import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs/Subscription';

import { DataService } from '@page2flip/core';
import { GridItem, Publication } from '@page2flip/core/common';
import { languages } from '@page2flip/i18n';
import { getTranslation } from '../../../locale/translation.provider';
import { PublicationDeleteDialogComponent } from '../publication-delete-dialog/publication-delete-dialog.component';

/**
 * Component that represents the publications table.
 */
@Component({
  selector: 'p2f-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: ['./publication-list.component.css']
})
export class PublicationListComponent implements OnInit, OnDestroy {

  /** Reference to the Perfect Scrollbar instance. */
  @ViewChild(PerfectScrollbarDirective) private perfectScrollbar: PerfectScrollbarDirective;

  /** Reference to the paginator instance. */
  @ViewChild(MatPaginator) private paginator: MatPaginator;

  /** Reference to the sort header instance. */
  @ViewChild(MatSort) private sort: MatSort;

  /** Subscriptions of the component. */
  private readonly subscriptions: Subscription[] = [];

  /** Date format. */
  readonly dateFormat: string = getTranslation('_shortDateFormat') || 'dd/MM/yy, HH:mm';

  /** Displayed columns. */
  readonly displayedColumns = [
    'cover',
    'language',
    'title',
    'description',
    'keywords',
    'author',
    'created',
    // 'modified',
    'published',
    'actions'
  ];

  /** Data source. */
  dataSource: MatTableDataSource<Publication>;

  /**
   * Constructor of the component.
   *
   * @param data      Service to fetch data from the backend.
   * @param date      Formats a date according to locale rules.
   * @param dialog    Service to open Material Design modal dialogs.
   * @param renderer  Service that provides a low-level interface for modifying the UI.
   */
  constructor(private data: DataService,
              private date: DatePipe,
              private dialog: MatDialog,
              private renderer: Renderer2) {
    this.renderer.listen('body', 'click', (event: MouseEvent | any) => {
      // HACK ////////////////////////////////////////////////////////////////////////
      //
      // Situation:
      //    1.) User changes the page size of the table to a value which makes
      //        its body taller than the current viewport (table body gets scrollable).
      //    2.) User scrolls to the bottom of the table.
      //    3.) User changes to page size of the table to a value which makes
      //        its body smaller than the current viewport again.
      //
      // Problem:
      //    The steps above result in a state, where the Perfect Scrollbar plugin is
      //    not able to correctly calculate the height of the table body due to the
      //    timing of the table paginator event.
      //
      // Solution:
      //    1.) Listen for a click event on the paginator drop-down,
      //    2.) scroll to the top of the table body,
      //    3.) change its page size to a small value,
      //    4.) request a new animation frame,
      //    5.) set the page size of the table to the actual value the user requested.
      //
      if (event.target.localName === 'mat-option') {
        this.perfectScrollbar.scrollToTop();
        this.paginator._changePageSize(1);
        requestAnimationFrame(() => this.paginator._changePageSize(parseInt(event.target.textContent, 0)));
      }
    });
  }

  /**
   * Subscribes to and sets up the data source.
   */
  ngOnInit() {
    this.subscriptions[this.subscriptions.length] = this.data.loadGridItems().subscribe((gridItems: GridItem[]) => {
      this.dataSource = new MatTableDataSource<Publication>(
        gridItems
          .map((gridItem: GridItem) => {
            if (gridItem.publication && gridItem.publication.present && gridItem.publication.publication) { // TODO: rename gridItem.publication.publication
              const publication: Publication = gridItem.publication.publication;
              publication.itemId = gridItem.id;
              return publication;
            }
          })
          .filter((publication: Publication) => publication)
          .sort((a, b) => new Date(b.dates.creationDate).getTime() - new Date(a.dates.creationDate).getTime())
      );
      this.dataSource.filterPredicate = (data: Publication, filter: string) => (
        data.dates.creationDate && this.date.transform(data.dates.creationDate, 'MMMM d, yyyy, HH:mm').includes(filter) ||
        data.dates.modificationDate && this.date.transform(data.dates.modificationDate, 'MMMM d, yyyy, HH:mm').includes(filter) ||
        data.dates.publicationStartDate && this.date.transform(data.dates.publicationStartDate, 'MMMM d, yyyy, HH:mm').includes(filter) ||
        data.meta.author.toLowerCase().includes(filter) ||
        data.meta.description.toLowerCase().includes(filter) ||
        data.meta.keywords.toLowerCase().includes(filter) ||
        data.meta.title.toLowerCase().includes(filter) ||
        this.getLanguageNativeName(data.meta.language).toLowerCase().includes(filter)
      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'author':
          case 'description':
          case 'keywords':
          case 'language':
          case 'title':
            return item.meta[property];
          case 'title-descr':
            return item.meta.title;
          case 'created':
            return item.dates.creationDate;
          case 'modified':
            return item.dates.modificationDate;
          case 'published':
            return item.dates.publicationStartDate;
          default:
            return item[property];
        }
      };
    });
  }

  /**
   * Unsubscribes from the data source.
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Filters the data source.
   *
   * @param filterValue Filter value.
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Gets the cover URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getCoverUrl(publicationId: number): string {
    return this.data.publicationUrl(publicationId, 'images/cover.png');
  }

  /**
   * Gets the URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getPublicationUrl(publicationId: number): string {
    return this.data.publicationUrl(publicationId, 'index.html');
  }

  /**
   * Gets the wizard URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getWizardUrl(publicationId: number): string {
    return this.data.wizardUrl(publicationId);
  }

  /**
   * Gets a languages' native name.
   *
   * @param code  ISO 639-1 language code.
   */
  getLanguageNativeName(code: string) {
    return languages[code].nativeName;
  }

  /**
   * Opens the dialog for deleting a publication.
   *
   * @param publication The publication.
   */
  openDeleteDialog(publication: Publication) {
    this.dialog.open(PublicationDeleteDialogComponent, {
      data: publication,
      width: '630px'
    });
  }

}
