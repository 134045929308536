import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DataService } from '@page2flip/core';
import { Publication } from '@page2flip/core/common';
import { languages } from '@page2flip/i18n';
import { getTranslation } from '../../../locale/translation.provider';

/**
 * Component that represents the publication details dialog.
 */
@Component({
  selector: 'p2f-publication-delete-dialog',
  templateUrl: './publication-delete-dialog.component.html',
  styleUrls: ['./publication-delete-dialog.component.css']
})
export class PublicationDeleteDialogComponent {

  /** Date format. */
  readonly dateFormat: string = getTranslation('_longDateFormat') || 'EEEE, MMMM d, yyyy, HH:mm';

  /**
   * Constructor of the component.
   *
   * @param publication Data passed to the dialog instance.
   * @param dialogRef   Reference to the dialog instance.
   * @param data        Service to fetch data from the backend.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public publication: Publication,
              public dialogRef: MatDialogRef<PublicationDeleteDialogComponent>,
              private data: DataService) {
    console.log(this.publication);
  }

  /**
   * Gets the cover URL of a publication.
   *
   * @param publicationId Publication ID
   */
  getCoverUrl(publicationId: number): string {
    return this.data.publicationUrl(publicationId, 'images/cover.png');
  }

  /**
   * Gets a languages' native name.
   *
   * @param code  ISO 639-1 language code.
   */
  getLanguageNativeName(code: string) {
    return languages[code].nativeName;
  }

  /**
   * Deletes the publication.
   */
  delete() {
    this.data.deletePublication(this.publication).subscribe(response => window.location.reload()); // FIMXE
  }

}
