import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { Configuration, GridItem, Hotspot, Page, Publication } from '@page2flip/core/common';
import { environment } from '../../../../../apps/creator/src/environments/environment';

/**
 * Service to fetch data from the backend.
 */
@Injectable()
export class DataService {

  /** Subject that controls the loading of a publication. */
  private publicationLoadedSubject: Subject<number> = new Subject<number>();

  /** The loaded publication. */
  private _publication: Publication;

  /** The configuration of the viewer . */
  private _configuration: Configuration;

  /** The hotspots of the publication. */
  private _hotspots: Hotspot[];

  /** The pages of the publication. */
  private _pages: Page[];

  /** Publication ID. */
  private publicationId: number;

  /** Publication language. */
  private language: string = 'de';

  /**
   * Constructor of the service.
   *
   * @param http  Service to perform HTTP requests.
   */
  constructor(private http: HttpClient) {}

  /**
   * Loads all grid items.
   */
  loadGridItems(): Observable<GridItem[]> {
    return this.http.get<GridItem[]>(`${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/items/gridItem`);
  }

  /**
   * Loads a publication.
   *
   * @param publicationId The publication ID.
   * @param language      The publication language.
   */
  async loadPublication(publicationId: number, language?: string) {
    this.publicationId = publicationId;
    this.language = language;

    const dataUrl = this.publicationUrl(publicationId, 'data/');
    this._publication = <Publication>await this.http.get(dataUrl + 'publication.json').toPromise();
    this._configuration = <Configuration>await this.http.get(dataUrl + 'config.json').toPromise();
    this._hotspots = <Hotspot[]>await this.http.get(dataUrl + 'hotspots.json').toPromise();
    this._pages = <Page[]>await this.http.get(dataUrl + 'pages.json').toPromise();
    this.publicationLoadedSubject.next(publicationId);
  }

  /**
   * Unloads a publication.
   */
  unloadPublication() {
    delete this._publication;
    delete this._configuration;
    delete this._hotspots;
    delete this._pages;
  }

  /**
   * Updates a publication.
   *
   * @param publication The publication.
   */
  updatePublication(publication: Publication) {
    console.log(publication);

    const patch = [];

    Object.entries(publication.meta).forEach(entry => {
      const key: string = entry[0];
      const value: string = entry[1];

      patch.push({
        op: 'replace',
        path: '/meta/' + key,
        value: value
      });
    });

    const headers: HttpHeaders = new HttpHeaders(
      {'Content-Type': 'application/json-patch+json'}
    );

    return this.http.patch(`${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/item/${publication.itemId}/p2fprop/publication/format/source/patchjson/publication.json`, patch, {headers, observe: 'response', responseType: 'text'});
  }

  /**
   * Updates a publications hotspots.
   *
   * @param publication The publication.
   * @param hotspots    The hotspots.
   */
  updatePublicationHotspots(publication: Publication, hotspots: Hotspot[]) {
    console.log(hotspots, publication.meta);

    const headers: HttpHeaders = new HttpHeaders(
      {'Content-Type': 'application/json-patch+json'}
    );

    return this.http.patch(`${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/item/${publication.itemId||this.publicationId}/p2fprop/publication/language/${this.language}/format/source/patchhotspots`, hotspots, {headers, observe: 'response', responseType: 'text'});
  }

  /**
   * Deletes a publication.
   *
   * @param publication The publication.
   */
  deletePublication(publication: Publication) {
    return this.http.delete(`${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/item/${publication.itemId}`, {observe: 'response'});
  }

  /**
   * Gets the URL of a publication file.
   *
   * @param publicationId Publication ID
   * @param file          Publication file
   */
  publicationUrl(publicationId: number, file?: string): string {
    return `${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/item/${publicationId}/p2fprop/publication/language/${this.language}/format/source/files/${file}`;
  }

  /**
   * Gets the download URL of a publication.
   *
   * @param publicationId Publication ID
   * @param fileName      Download file name
   */
  publicationDownloadUrl(publicationId: number, fileName?: string): string {
    return `${environment.spaceOneApiUrl}/scope/${environment.spaceOneScope}/item/${publicationId}/p2fprop/publication/format/source/download?filename=${fileName}`; // FIXME: filename parameter does not work
  }

  /**
   * Gets the wizard URL of a publication.
   *
   * @param publicationId Publication ID
   */
  wizardUrl(publicationId: number): string {
    return `/wizard/${environment.spaceOneInstance}/${environment.spaceOneScope}/${publicationId}`;
  }

  /**
   * Gets an observable that is notified when the publication has been loaded.
   */
  get publicationLoaded(): Observable<number> {
    return this.publicationLoadedSubject.asObservable();
  }

  /**
   * Gets the loaded publication.
   */
  get publication(): Publication {
    return this._publication;
  }

  /** Gets the viewer configuration of the loaded publication. */
  get configuration(): Configuration {
    return this._configuration;
  }

  /**
   * Gets the hotspots of the loaded publication.
   */
  get hotspots(): Hotspot[] {
    return this._hotspots;
  }

  /**
   * Gets the pages of the loaded publication.
   */
  get pages(): Page[] {
    return this._pages;
  }

}
