import { Injectable } from '@angular/core';
import { HammerInstance } from '@angular/material';
import { HammerGestureConfig } from '@angular/platform-browser';

/**
 * An injectable [HammerJS Manager](http://hammerjs.github.io/api/#hammer.manager)
 * for gesture recognition. Configures specific event recognition.
 */
@Injectable()
export class HammerConfig extends HammerGestureConfig {

  /**
   * A set of supported event names for gestures to be used in Angular.
   * Angular supports all built-in recognizers, as listed in
   * [HammerJS documentation](http://hammerjs.github.io/).
   */
  events: string[] = ['pinch swipe tap'];

  /**
   * Creates a [HammerJS Manager](http://hammerjs.github.io/api/#hammer.manager)
   * and attaches it to a given HTML element.
   *
   * @param element The element that will recognize gestures.
   * @returns A HammerJS event-manager object.
   */
  buildHammer(element: HTMLElement): HammerInstance {
    // Hammer disables text selection by default to improve the UX of the panning on desktop.
    // Since we don't use panning, but care about text selection, we can remove this property.
    delete Hammer.defaults.cssProps.userSelect;

    const mc = new Hammer(element, { domEvents: true });

    mc.get('pinch').set({ enable: true });
    mc.get('swipe').set({ enable: true });
    mc.get('tap').set({
      enable: true,
      posThreshold: 25
    });

    return mc;
  }

}
