import { LOCALE_ID, StaticProvider, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';

import { locales } from '@page2flip/i18n';

const locale: string = document.getElementsByTagName('html')[0].getAttribute('lang');
const translations: string = locales.includes(locale) ? require(`./../../../../libs/i18n/src/lib/translations/${locale}.translation.ts`).translations : null;

export function getTranslationProviders(): StaticProvider[] {
  return translations ? [
    { provide: TRANSLATIONS, useValue: require(`raw-loader!./messages.${locale}.xlf`) },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
    { provide: LOCALE_ID, useValue: locale }
  ] : [];
}

export function getTranslation(id: string): string {
  return translations ? translations[id] || '' : null;
}
