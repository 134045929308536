import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '@page2flip/core';
import { Instance } from '@page2flip/core/common';
import { environment } from '../../../environments/environment';

/**
 * Service to set the space.one instance.
 */
@Injectable()
export class SpaceOneInstanceService {

  /** Available space.one instances. */
  private _spaceOneInstances: Instance[] = [  // TODO: detect first scope
    { apiUrl: 'https://ngpages.devdock.space.one/api', host: 'ngpages.devdock.space.one', scope: 'demo' },
    { apiUrl: 'https://ecoroll.devdock.space.one/api', host: 'ecoroll.devdock.space.one', scope: 'ecoroll' },
    { apiUrl: 'https://kpmg.customer.space.one/api', host: 'kpmg.customer.space.one', scope: 'kpmg' }
  ];

  /** Current space.one instance. */
  private _spaceOneInstance: Instance;

  /**
   * Constructor of the service.
   *
   * @param window  DI Token for the Window object.
   */
  constructor(@Inject(WINDOW) private window: any) {
    this.addDevelopmentInstance();
    this.getSpaceOneInstance();
  }

  /**
   * Returns the list of available space.one instances.
   */
  get spaceOneInstances(): Instance[] {
    return this._spaceOneInstances;
  }

  /**
   * Returns the current space.one instance.
   */
  get spaceOneInstance(): Instance {
    return this._spaceOneInstance;
  }

  /**
   * Adds space.one development instance when serving from local development server.
   */
  private addDevelopmentInstance() {
    if (this.window.location.hostname.includes('localhost')) {
      this._spaceOneInstances.push({ apiUrl: 'https://localhost:8443/api', host: 'localhost:8443' , scope: 'test'}); // TODO: detect first scope
      this._spaceOneInstances.push({ apiUrl: 'http://localhost:8080/api', host: 'localhost:8080' , scope: 'test'}); // TODO: detect first scope
    }
  }

  /**
   * Gets the space.one instance.
   */
  private getSpaceOneInstance() {
    const instance: Instance = JSON.parse(this.window.sessionStorage.getItem(('spaceOneInstance'))) || null;

    if (instance) {
      this._spaceOneInstances.forEach(spaceOneInstance => {
        if (spaceOneInstance.host === instance.host) {
          this._spaceOneInstance = spaceOneInstance;
        }
      });
    } else {
      this._spaceOneInstance = this._spaceOneInstances[0];
    }

    this.updateEnvironment();
  }

  /**
   * Sets the space.one instance.
   */
  public setSpaceOneInstance(instance: Instance) {
    this.window.sessionStorage.setItem('spaceOneInstance', JSON.stringify(instance));
    this._spaceOneInstance = instance;
    this.updateEnvironment();
  }

  /**
   * Updates the application environment.
   */
  private updateEnvironment() {
    if (this._spaceOneInstance) {
      environment.spaceOneApiUrl = this._spaceOneInstance.apiUrl;
      environment.spaceOneInstance = this._spaceOneInstance.host;
      environment.spaceOneScope = this._spaceOneInstance.scope;
    }

    console.log(`[${this.constructor.name}] environment:`, environment);
  }

}
