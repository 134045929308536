import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatProgressSpinnerModule } from '@angular/material';

import { BrowserDimensionsComponent } from './components/browser-dimensions/browser-dimensions.component';
import { BrowserErrorComponent } from './components/browser-error/browser-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './components/loader/loader.service';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { KeyPipe } from './pipes/key.pipe';

/** Declarations of the module. */
const declarations: any[] = [
  BrowserErrorComponent,
  BrowserDimensionsComponent,
  KeyPipe,
  LoaderComponent,
  StopPropagationDirective
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [...declarations],
  declarations: [...declarations],
  providers: [LoaderService]
})
export class CoreCommonModule {}
