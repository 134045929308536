import { Routes } from '@angular/router';

import { FeedbackComponent } from './base/feedback/feedback.component';
import { HomeComponent } from './base/home/home.component';
import { InfoComponent } from './base/info/info.component';
import { LoginComponent } from './base/login/login.component';
import { NotFoundComponent } from './base/not-found/not-found.component';
import { PublicationListComponent } from './base/publication-list/publication-list.component';
import { SettingsComponent } from './base/settings/settings.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';

export const routes: Routes = [
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'info',
    component: InfoComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'publications',
    component: PublicationListComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'wizard',
    loadChildren: './wizard/wizard.module#WizardModule'
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthenticationGuard]
  }
];
