import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';

import { getTranslation } from '../../../../apps/creator/src/locale/translation.provider'; // FIXME: Angular 7.0 runtime i18n

/**
 * Custom MatPaginatorIntl instance to modify the labels and texts displayed.
 */
@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {

  /** A label for the page size selector. */
  itemsPerPageLabel = getTranslation('paginatorItemsPerPageLabel') || 'Items per page:';

  /** A label for the button that increments the current page. */
  nextPageLabel = getTranslation('paginatorNextPageLabel') || 'Next page';

  /** A label for the button that decrements the current page. */
  previousPageLabel = getTranslation('paginatorPreviousPageLabel') || 'Previous page';

  /** A label for the button that moves to the first page. */
  firstPageLabel = getTranslation('paginatorFirstPageLabel') || 'First page';

  /** A label for the button that moves to the last page. */
  lastPageLabel = getTranslation('paginatorLastPageLabel') || 'Last page';

  /** A label for the range of items within the current page and the length of the whole list. */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) { return `0 ${getTranslation('paginatorRangeOfLabel') ||'of'} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${getTranslation('paginatorRangeOfLabel') ||'of'} ${length}`;
  }

}
